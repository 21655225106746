



















import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

declare interface AbstractCategory {
    name: string;
    slug: string;
    image: string;
    amount: number;
}

@Component({
    components: {
        ProductCategoryCard: () => import('@/components/product/category/ProductCategoryCard.vue'),
    },
})
export default class ProductCategoryRow extends Vue {
    @Getter('collection/all') collections!: Collection[];

    paint: AbstractCategory | undefined = undefined;
    nonPaint: AbstractCategory | undefined = undefined;
    readyMix: AbstractCategory | undefined = undefined;
    cans: AbstractCategory | undefined = undefined;

    loaded: boolean = false;

    mounted() {
        const paint = this.collections.find((c: Collection) => c.slug === '6-paint');
        const nonPaint = this.collections.find((c: Collection) => c.slug === '7-non-paint');
        const readyMix = this.collections.find((c: Collection) => c.slug === '8-readymix');
        const cans = this.collections.find((c: Collection) => c.slug === '10-spuitbussen');

        this.paint = {
            name: 'Paint',
            image: '/img/paint-gun.png',
            // @ts-ignore
            amount: paint != null ? paint.total_products : 0,
            slug: '6-paint',
        };

        this.nonPaint = {
            name: 'Non-Paint',
            image: '/img/duck-tape.png',
            // @ts-ignore
            amount: nonPaint != null ? nonPaint.total_products : 0,
            slug: '7-non-paint',
        };

        this.readyMix = {
            name: 'ReadyMix',
            image: '/img/paint-can.png',
            // @ts-ignore
            amount: readyMix != null ? readyMix.total_products : 0,
            slug: '8-readymix',
        };

        this.cans = {
            name: 'Spuitbussen',
            image: '/img/spray-paint.png',
            // @ts-ignore
            amount: cans != null ? cans.total_products : 0,
            slug: '10-spuitbussen',
        };

        this.loaded = true;
    }
}
